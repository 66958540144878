import axios from "axios";

export const ESTADOS_BRASILEIROS = [
    { sigla: "AC", nome: "Acre" },
    { sigla: "AL", nome: "Alagoas" },
    { sigla: "AP", nome: "Amapá" },
    { sigla: "AM", nome: "Amazonas" },
    { sigla: "BA", nome: "Bahia" },
    { sigla: "CE", nome: "Ceará" },
    { sigla: "DF", nome: "Distrito Federal" },
    { sigla: "ES", nome: "Espírito Santo" },
    { sigla: "GO", nome: "Goiás" },
    { sigla: "MA", nome: "Maranhão" },
    { sigla: "MT", nome: "Mato Grosso" },
    { sigla: "MS", nome: "Mato Grosso do Sul" },
    { sigla: "MG", nome: "Minas Gerais" },
    { sigla: "PA", nome: "Pará" },
    { sigla: "PB", nome: "Paraíba" },
    { sigla: "PR", nome: "Paraná" },
    { sigla: "PE", nome: "Pernambuco" },
    { sigla: "PI", nome: "Piauí" },
    { sigla: "RJ", nome: "Rio de Janeiro" },
    { sigla: "RN", nome: "Rio Grande do Norte" },
    { sigla: "RS", nome: "Rio Grande do Sul" },
    { sigla: "RO", nome: "Rondônia" },
    { sigla: "RR", nome: "Roraima" },
    { sigla: "SC", nome: "Santa Catarina" },
    { sigla: "SP", nome: "São Paulo" },
    { sigla: "SE", nome: "Sergipe" },
    { sigla: "TO", nome: "Tocantins" }
];

/**
 * Busca o endereço a partir do CEP usando a API ViaCEP
 * @param {string} cep - CEP a ser consultado
 * @returns {Promise<Object>} Dados do endereço
 */
// export const buscarEnderecoPorCEP = async (cep) => {
//     try {
//         const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
//         return response.data;
//     } catch (error) {
//         console.error('Erro ao buscar CEP:', error);
//         throw new Error('Não foi possível buscar o CEP. Tente novamente.');
//     }
// };

export const buscarEnderecoPorCEP = async (cep) => {
    try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const data = response.data;
        
        if (!data.erro) {
            return {
                ...data,
                pais: "Brasil"
            };
        }
        return data;
    } catch (error) {
        console.error('Erro ao buscar CEP:', error);
        throw new Error('Não foi possível buscar o CEP. Tente novamente.');
    }
};

/**
 * Busca cidades de um estado usando a API do IBGE
 * @param {string} uf - Sigla do estado
 * @returns {Promise<string[]>} Lista de nomes de cidades
 */
export const buscarCidadesPorEstado = async (uf) => {
    try {
        const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
        return response.data.map(cidade => cidade.nome);
    } catch (error) {
        console.error('Erro ao buscar cidades:', error);
        throw new Error('Não foi possível buscar as cidades. Tente novamente.');
    }
};

/**
 * Formata o endereço em uma string única
 * @param {Object} endereco - Dados do endereço
 * @returns {string} Endereço formatado
 */
export const formatarEndereco = (endereco) => {
    if (!endereco) return '';

    const {
        logradouro,
        numero,
        complemento,
        bairro,
        cidade,
        estado,
        cep, 
        pais
    } = endereco;

    return `${logradouro}, ${numero}${complemento ? `, ${complemento}` : ''}, ${bairro}, ${cidade} - ${estado}, CEP: ${cep}`;
}; 