import React, { useState, useEffect, useContext } from "react";
import * as S from './NovoRelatorioStyle';
import SideBarBox from "../Sidebar/SideBarBox";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { usePulse } from "../../context/LoadContext";
import html2pdf from 'html2pdf.js';
import { formatNumber } from "../../assets/utils";
import { saveAs } from 'file-saver';

const BASE = process.env.REACT_APP_BASE_ROUTE;
const DESTIN = process.env.REACT_APP_RELATORIO;

export default function NovoRelatorio() {
    const { userData } = useContext(AuthContext);
    const { showPulse, hidePulse } = usePulse();
    const [contratosEsseAninho, setContratosEsseAninho] = useState([]);

    const sortContractsByDate = (contracts) => {
        return [...contracts].sort((a, b) => {
            return new Date(a.PURCHASEDATE) - new Date(b.PURCHASEDATE);
        });
    };


    // const obterContratosPorAno = () => {
    //     const contratosEsseAno = [];
    //     if (userData.CONTRATOS && userData.CONTRATOS.length > 0) {
    //         userData.CONTRATOS.forEach(contrato => {
    //             if (contrato && contrato.STATUS && (contrato.STATUS === 1 || contrato.STATUS === 2)) {
    //                 if (contrato.STATUS === 1) {
    //                     const dataHoje = new Date();
    //                     const dataInicio = new Date('2024-01-01');
    //                     const diffTime = Math.abs(dataHoje - dataInicio);
    //                     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    //                     var valorizacaoTotal = (parseFloat(contrato.MAXIMUMQUOTAYIELD) /
    //                         (parseFloat(contrato.MAXIMUMNUMBEROFDAYSTOYIELD) * 30)) * diffDays;

    //                     var valorizacaoTotalDinheiro = (valorizacaoTotal / 100) *
    //                         parseFloat(contrato.TOTALSPENT > contrato.TOTALSPENTFEE ? contrato.TOTALSPENT : contrato.TOTALSPENTFEE);

    //                     var valorPlus = 0;
    //                     if (userData.PLUS && userData.PLUS.length > 0) {
    //                         userData.PLUS.forEach(p => {
    //                             if (parseFloat(p.IDCOMPRA) === parseFloat(contrato.IDCOMPRA)) {
    //                                 valorPlus += parseFloat(p.value_multiplied);
    //                             }
    //                         })
    //                     }

    //                     contratosEsseAno.push({ ctrId: contrato.IDCOMPRA, TOTALSPENT: contrato.TOTALSPENT , value: (valorizacaoTotalDinheiro + valorPlus) || 0 });
    //                 }
    //             }
    //         });
    //     }

    //     if (contratosEsseAno && contratosEsseAno.length > 0) {
    //         setContratosEsseAninho(contratosEsseAno);
    //     }
    // }

    const obterContratosPorAno = () => {
        const contratosEsseAno = [];

        if (userData.CONTRATOS && userData.CONTRATOS.length > 0) {
            userData.CONTRATOS.forEach(contrato => {
                if (contrato && contrato.STATUS && (contrato.STATUS === 1 || contrato.STATUS === 2)) {
                    // Verifica se o contrato está ativo (STATUS === 1)
                    if (contrato.STATUS === 1) {
                        const dataInicioContrato = new Date(contrato.PURCHASEDATE); // Data de início do contrato
                        const dataFinal2024 = new Date('2024-12-31'); // Último dia de 2024
                        const dataInicio2024 = new Date('2024-01-01'); // Primeiro dia de 2024

                        let dataInicioCalculo; // Data de início para o cálculo
                        let dataFimCalculo; // Data de término para o cálculo

                        // Define a data de início do cálculo
                        if (dataInicioContrato >= dataInicio2024) {
                            // Contrato adquirido em 2024: começa a valorizar a partir da data de início
                            dataInicioCalculo = dataInicioContrato;
                        } else {
                            // Contrato adquirido antes de 2024: começa a valorizar a partir de 1º de janeiro de 2024
                            dataInicioCalculo = dataInicio2024;
                        }

                        // Define a data de término do cálculo
                        if (contrato.YIELDTERM) {
                            const dataYieldTerm = new Date(contrato.YIELDTERM);
                            if (dataYieldTerm <= dataFinal2024) {
                                // Se o YIELDTERM for dentro de 2024, usa essa data
                                dataFimCalculo = dataYieldTerm;
                            } else {
                                // Se o YIELDTERM for após 2024, usa o final de 2024
                                dataFimCalculo = dataFinal2024;
                            }
                        } else {
                            // Se não houver YIELDTERM, usa o final de 2024
                            dataFimCalculo = dataFinal2024;
                        }

                        // Calcula o número de dias entre as datas
                        const diffTime = Math.abs(dataFimCalculo - dataInicioCalculo);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                        // Calcula o lucro diário
                        const lucroDiario = (parseFloat(contrato.TOTALSPENT) * parseFloat(contrato.MAXIMUMQUOTAYIELD)) /
                            (parseFloat(contrato.MAXIMUMNUMBEROFDAYSTOYIELD) * 30);

                        // Calcula o valor acumulado em 2024
                        const valorAcumulado2024 = (0.0013998 * parseFloat(contrato.TOTALSPENT)) * diffDays;

                        var valorDePlus = 0;

                        if (userData.PLUS && userData.PLUS.length > 0) {
                            userData.PLUS.forEach(plus => {
                                if (plus && parseFloat(plus.IDCOMPRA) === parseFloat(contrato.IDCOMPRA)) {
                                    valorDePlus += parseFloat(plus.value_multiplied);
                                }
                            })
                        }

                        contratosEsseAno.push({
                            ctrId: contrato.IDCOMPRA,
                            TOTALSPENT: parseFloat(contrato.TOTALSPENT),
                            value: (valorAcumulado2024 + valorDePlus) || 0
                        });
                    }
                }
            });
        }

        if (contratosEsseAno && contratosEsseAno.length > 0) {
            setContratosEsseAninho(contratosEsseAno);
        }
    };

    useEffect(() => {
        obterContratosPorAno();
    }, []);

    const getValorizacaoArray = () => {
        var aux = [];
        contratosEsseAninho.forEach(val => {
            aux.push([val.ctrId, `R$${formatNumber(val.TOTALSPENT)}`, `R$${formatNumber(val.value)}`]); // Ajuste para o formato esperado
        });
        return aux;
    }

    const gerarPDF = async () => {
        showPulse()
        try {
            const data = {
                clientInfo: [
                    {
                        nome: userData.NAME || "",
                        cpf: userData.CPF,
                        usuario: userData.USERNAME
                    }
                ],
                columnNames: ["ID CONTRATO", "VALOR COMPRA", "VALORIZAÇÃO EM 2024"],
                rowData: getValorizacaoArray()
            };


            const response = await axios.post(
                `${process.env.REACT_APP_BASE_ROUTE}/relatorioGeralConta`,
                data,
                {
                    responseType: 'blob'
                }
            );


            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            console.log("Blob criado:", pdfBlob); // Verifique o Blob

            saveAs(pdfBlob, 'RelatorioDeValorizacao2024.pdf');
            console.log("PDF salvo com sucesso!"); // Verifique se o PDF foi salvo
        } catch (error) {
            console.error('Erro ao gerar o PDF:', error);
        }
        hidePulse();
    };


    return (
        <SideBarBox>
            <S.RelatorioContainer id="relatorio">
                <S.LoginBehind src='logo-golden.png' />
                <S.Titulo>RELATÓRIO DE VALORIZAÇÃO 2024 🧾</S.Titulo>
                <S.BotaoGerarImprimirPDF>
                    <button onClick={gerarPDF}>Download</button>
                </S.BotaoGerarImprimirPDF>
            </S.RelatorioContainer>
        </SideBarBox>
    );
}