import styled from "styled-components";


export const RelatorioContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    // background: linear-gradient(to right, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF);
    background-image: url('textura.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    box-sizing: border-box;
    padding: 50px 30px 200px 30px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1000px){
        flex-direction: column;
        justify-content: center;
        padding: 40px 20px 100px 20px;
    }
`;

export const LoginBehind = styled.img`
    position: fixed;
    width: 350px;
    top: 30%; /* Você pode ajustar ou remover esta linha se quiser posicionar verticalmente de outra forma */
    z-index: 1;
    left: 50%;
    opacity: 0.5;
    transform: translateX(-50%); /* Isso centraliza a imagem horizontalmente */
`;

export const Titulo = styled.div`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-size: 32px;
    color: rgba(0,0,0,0.8);
    font-weight: 800;
    text-align: center;
    z-index: 2;
`;

export const BotaoGerarImprimirPDF = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;

    button{
        width: 300px;
        height: 40px;
        font-size: 20px;
        cursor: pointer;
        background: rgba(30, 200, 0, 1);
        border-radius: 4px;
        color: white;
        font-weight: 600;
        font-style: italic;
        border: 0;
        transition: .3s;

        &:hover{
            text-decoration: underline;
        }
    }
`;