import React, { useState, useEffect, useContext } from "react";
import * as S from "./RegisterIncompletedStyle";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, doc, getDoc, updateDoc, arrayUnion, setDoc } from 'firebase/firestore';
import { db } from "../../database/firebaseConfig";

export default function RegisterIncompleted() {

    const { userData, reloadUserData, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [address, setAddress] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [state, setState] = useState("");


    useEffect(() => {
        if (userData) {
            userData.NAME && setName(userData.NAME);
            userData.ADRESS && setAddress(userData.ADRESS);
            userData.NEIGHBORHOOD && setNeighborhood(userData.NEIGHBORHOOD);
            userData.CITY && setCity(userData.CITY);
            userData.POSTALCODE && setZipcode(userData.POSTALCODE);
            userData.STATE && setState(userData.STATE);

            if (userData.CONTACT && !(userData.CONTACT.replace("-", "").replace("(", "").replace(")", "").trim().length != 11)) {
                setContact(userData.CONTACT)
            }
        }
    }, [userData])

    const handleRegisterIncomplete = () => {
        if (userData) {
            if (name && name.trim().split(" ").length === 1)
                return false;
            if (zipcode && zipcode.trim() === "")
                return false;
            if (state && state.trim() === "")
                return false;
            if (address && address.trim() === "")
                return false;
            if (city && city.trim() === "")
                return false;
            if (neighborhood && neighborhood.trim() === "")
                return false;
            if (!contact || ((contact.trim() === "" || contact.replace("-", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").trim().length != 11)))
                return false;
            return true;
        }
    }

    function removerAcentos(texto) {
        return texto.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[´`^~]/g, '');
    }

    const handleConfirm = async () => {
        if (!handleRegisterIncomplete()) {
            alert("Complete seu cadastro.");
            return;
        }

        try {
            const userDocRef = doc(db, "USERS", userData.CPF);

            await updateDoc(userDocRef, {
                NAME: name.toUpperCase(),
                NAME_SEMACENTO: removerAcentos(name).toUpperCase(),
                CONTACT: contact,
                ADRESS: address,
                NEIGHBORHOOD: neighborhood,
                CITY: city,
                POSTALCODE: zipcode,
                STATE: state,
                COMPLETED: true
            });
            // await reloadUserData();
            logout();
            alert("Cadastro atualizado com sucesso! Faça o login novamente");
            navigate("/dashboard");
        } catch (error) {
            console.error("Erro ao atualizar cadastro:", error);
            alert("Ocorreu um erro ao atualizar o cadastro. Tente novamente.");
        }
    };

    if (!userData)
        return;

    return (
        <>
            <S.ContainerComplete>
                <S.LoginBehind src='logo-golden.png' />
                <S.ContentContainer>
                    <S.Header>
                        <span className="titulo">Complete seu cadastro</span>
                    </S.Header>
                    <S.Body>
                        <S.ItemProperty>
                            <span className="nome">NOME COMPLETO</span>
                            <input value={name} onChange={(e) => setName(e.target.value.toUpperCase())} type="text" className="inputBox" />
                        </S.ItemProperty>
                        <S.ItemProperty>
                            <span className="nome">CONTATO</span>
                            <input value={contact} onChange={(e) => setContact(e.target.value)} type="text" className="inputBox" placeholder="(DDD) + Celular" />
                        </S.ItemProperty>
                        <S.ItemProperty>
                            <span className="nome">ENDEREÇO</span>
                            <input value={address} onChange={(e) => setAddress(e.target.value)} type="text" className="inputBox" placeholder="Rua,Av... nº" />
                        </S.ItemProperty>
                        <S.ItemProperty>
                            <span className="nome">CIDADE</span>
                            <input value={city} onChange={(e) => setCity(e.target.value)} type="text" className="inputBox" placeholder="..." />
                        </S.ItemProperty>
                        <S.ItemProperty>
                            <span className="nome">BAIRRO</span>
                            <input value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)} type="text" className="inputBox" placeholder="..." />
                        </S.ItemProperty>
                        <S.ItemProperty>
                            <span className="nome">CEP</span>
                            <input value={zipcode} onChange={(e) => setZipcode(e.target.value)} type="text" className="inputBox" placeholder="00000-000" />
                        </S.ItemProperty>
                    </S.Body>
                    <S.Confirmation>
                        <button className="save" onClick={handleConfirm}>CONFIRMAR</button>
                        <button className="cancel" onClick={() => navigate("/dashboard")}>VOLTAR E COMPLETAR MAIS TARDE</button>
                    </S.Confirmation>
                </S.ContentContainer>
            </S.ContainerComplete>
        </>
    )
}