import styled from "styled-components";


export const ContainerComplete = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 40px;
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titulo{
        width: 100%;
        text-align: center;
        font-size: 48px;
        font-weight: 800;
        color: black;
    }
`


export const LoginBehind = styled.img`
    position: fixed;
    width: 350px;
    top: 30%; /* Você pode ajustar ou remover esta linha se quiser posicionar verticalmente de outra forma */
    z-index: 1;
    left: 50%;
    opacity: 0.5;
    transform: translateX(-50%); /* Isso centraliza a imagem horizontalmente */
`;

export const ContentContainer = styled.div`
    width: 100%;
    z-index: 2;
`

export const Body = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
`

export const ItemProperty = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .nome{
        font-size: 26px;
        color: black;
    }

    .inputBox{
        width: 100%;
        height: 45px;
        border: 1px solid rgba(0,0,0,0.6);
        box-shadow: 2px 2px 1px rgba(0,0,0,0.4);
        color: black;
        font-size: 18px;
        padding: 0;
        text-align: center;

        &::placeholder{
            color: black;
        }
    }
`

export const Confirmation = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    button{
        width: 100%;
        height: 40px;
        font-size: 18px;
        border: 1px solid rgba(0,0,0,0.6);
        box-shadow: 2px 2px 1px rgba(0,0,0,0.4);
        cursor: pointer;
        transition: .3s;

        &:hover{
            transform: scale(0.99);
        }
    }

    .save{
        background: rgba(40, 200, 10, 1);
        color: white;
    }

    .cancel{
        background: rgba(255, 100, 10, 1);
        color: white;
    }
`

// export const nome = styled.div``

// export const nome = styled.div``
